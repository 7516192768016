import React from 'react';

import useMedia from 'use-media';

import Notification from './notification';

function LowBreakPointNotification(props) {
  const hasEnoughViewArea = useMedia({ minWidth: '900px' });

  return hasEnoughViewArea ? props.children : <Notification />;
}

export default LowBreakPointNotification;
