/**
 * Calculates breakpoint sizes based on the selected device type.
 *
 * @return {number} - Calculated breakpoint size.
 */
function getCalculatedBreakpointSize(deviceType) {
  const sizes = {
    desktop: '100%',
    tablet: 720,
    mobile: 480,
  };

  return deviceType in sizes ? sizes[deviceType] : sizes.desktop;
}

export default getCalculatedBreakpointSize;
