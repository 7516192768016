import React, { useRef } from 'react';

import { isEmpty } from 'lodash';
import { Badge, Box, Heading, HStack } from '@chakra-ui/react';

import usePatterns from '../../store/patterns';

import PatternsList from '../patterns-list';

function SecondaryDrawer() {
  const activeCategory = usePatterns(state => state.activeCategory);
  const activeCategoryCount = usePatterns(state => state.activeCategoryCount);

  return isEmpty(activeCategory) ? null : (
    <Box
      top="0"
      right="-1px"
      zIndex={99}
      height="100vh"
      overflowY="scroll"
      bgColor="white"
      position="absolute"
      minW="350px"
      transform="translate(100%)"
    >
      <Box
        p="30px"
        borderBottom="1px solid"
        borderColor="gray.200"
        position="sticky"
        top="0"
        bgColor="white"
        w="100%"
        zIndex="99"
      >
        <HStack>
          <Heading size="md">{activeCategory?.name} Patterns</Heading>
          {activeCategoryCount > 0 && (
            <Badge colorScheme="blue"> {activeCategoryCount}</Badge>
          )}
        </HStack>
      </Box>
      <PatternsList />
    </Box>
  );
}

export default SecondaryDrawer;
