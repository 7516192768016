import React from 'react';

import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Button, Flex, Image, HStack } from '@chakra-ui/react';

import generateEditUrl from '../../functions/generate-edit-url';

import { useNavigate } from 'react-router-dom';

import usePatterns from '../../store/patterns';

import CopyCodeButton from '../copy-code-button';

function PreviewHeader(props) {
  const patterns = usePatterns(state => state.patterns);
  const navigation = useNavigate();

  return (
    <Flex
      p="30px"
      bgColor="gray.100"
      borderBottom="1px solid"
      borderBottomColor="gray.200"
      justifyContent="space-between"
    >
      <Image w="40px" src="/gutenberghub.png" />
      <HStack>
        <CopyCodeButton />
        <Button
          colorScheme="blue"
          variant="outline"
          rightIcon={<ExternalLinkIcon />}
          onClick={() => navigation(generateEditUrl(patterns))}
        >
          Edit
        </Button>
      </HStack>
    </Flex>
  );
}

export default PreviewHeader;
