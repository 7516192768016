import React, { useEffect } from 'react';

import usePatterns from '../../store/patterns';

import { CopyIcon } from '@chakra-ui/icons';
import { Button, useToast } from '@chakra-ui/react';

import { isEmpty } from 'lodash';

import useClipboard from '../../hooks/use-clipboard';

function CopyCodeButton() {
  const toast = useToast();

  const shortcode = usePatterns(state =>
    state.patterns.map(pattern => pattern?.pattern_content ?? '').join('')
  );

  const [isCopied, setCopied] = useClipboard(shortcode);

  const onSuccess = () => {
    toast({
      title: 'Code copied successfully',
      status: 'success',
    });
  };

  useEffect(() => {
    if (isCopied) {
      onSuccess();
    }
  }, [isCopied]);

  return (
    !isEmpty(shortcode) && (
      <Button
        colorScheme="blue"
        leftIcon={<CopyIcon />}
        onClick={() => setCopied(shortcode)}
      >
        Copy Code
      </Button>
    )
  );
}

export default CopyCodeButton;
