import React from 'react';

import { Routes, Route, Navigate } from 'react-router-dom';
import { ChakraProvider, theme } from '@chakra-ui/react';

import AppPage from './pages/app';
import Preview from './pages/preview';

import LoadersProvider from './providers/loaders-provider';
import SubscriptionsProvider from './providers/subscriptions-provider';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Routes>
        <Route path="/" element={<AppPage />} />
        <Route path="/preview" element={<Preview />} exact />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <LoadersProvider />
      <SubscriptionsProvider />
    </ChakraProvider>
  );
}

export default App;
