import usePatterns from '../store/patterns';

import patternsApi from '../api/patterns';

import { isEqual } from 'lodash';

import { createStandaloneToast } from '@chakra-ui/react';

/**
 * Sorts the remote patterns based on the url query.
 *
 * @param {array} remotePatterns - Remote patterns obtained from the API.
 */
function sortRemotePatternsAccordingToQueryParams(remotePatterns) {
  const params = new URLSearchParams(window.location.search);

  if (!params.has('patterns')) {
    return remotePatterns;
  }

  const sortedPatterns = [];

  const queryPatterns = params.get('patterns').split(',');

  queryPatterns.forEach(queryPatternId => {
    const remotePattern = remotePatterns.find(
      remotePattern => remotePattern?.id?.toString() === queryPatternId
    );

    if (remotePattern) {
      sortedPatterns.push(remotePattern);
    }
  });

  return sortedPatterns;
}

/**
 * Checks if the local session is still valid according to the query params
 *
 * @param {string} - JSON encoded, locally stored patterns from session storage.
 *
 * @return {boolean} True if valid, otherwise false.
 */
function isSessionValid(localPatterns) {
  const params = new URLSearchParams(window.location.search);

  if (!params.has('patterns')) {
    return false;
  }

  let queryPatterns = params.get('patterns'); // example -> 324,432,234,3432.
  queryPatterns = queryPatterns.split(','); // converting into readable array.

  // Only using pattern ids.
  localPatterns = JSON.parse(localPatterns).map(pattern =>
    pattern?.id.toString()
  );

  // Matching local patterns with query patterns in order to analyze the session validity.
  return isEqual(localPatterns, queryPatterns);
}

/***
 * This loader updates last selected patterns in the state.
 */
export default async function loadSelectedPatterns() {
  // Try 1: checking if lastly modified patterns are available in session storage.
  const localPatterns = sessionStorage.getItem('gutenberghub-patterns');

  if (localPatterns && isSessionValid(localPatterns)) {
    usePatterns.setState({ patterns: JSON.parse(localPatterns) });
    return true;
  }

  // Try 2: if we don't have patterns locally, obtaining them from the remote API.
  const params = new URLSearchParams(window.location.search);

  if (!params.has('patterns')) {
    return true;
  }

  usePatterns.setState({ loadingPatterns: true, error: false });

  try {
    const lastPatternIds = params.get('patterns').split(',');
    const remotePatterns = await patternsApi.getPatternsFromIDs(lastPatternIds);

    usePatterns.setState({
      error: false,
      loadingPatterns: false,
      patterns: sortRemotePatternsAccordingToQueryParams(remotePatterns),
    });
  } catch (error) {
    const toast = createStandaloneToast();

    toast({
      title: 'Failed to load patterns',
      description: 'Unable to load patterns. Please try again later.',
      status: 'error',
    });

    usePatterns.setState({ error: true });
  } finally {
    usePatterns.setState({ loadingPatterns: false });
  }
}
