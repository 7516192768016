import React from 'react';

import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Text, Heading, Center, VStack, Button } from '@chakra-ui/react';

import { useNavigate } from 'react-router-dom';

import BigOwl from '../../icons/big-owl';

function NoDirectVisit() {
  const navigation = useNavigate();

  return (
    <Center h="100vh">
      <VStack>
        <BigOwl />
        <Heading>Oops, Seems like you are lost</Heading>
        <Text color="gray.400" fontSize="2xl">
          Currently, There is nothing to preview here.
        </Text>
        <Button
          colorScheme="blue"
          rightIcon={<ExternalLinkIcon />}
          onClick={() => navigation('/')}
        >
          Get back to the App
        </Button>
      </VStack>
    </Center>
  );
}

export default NoDirectVisit;
