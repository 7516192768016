import { useState, useEffect } from 'react';
import axios from 'axios';

axios.defaults.baseURL = 'https://wordpress.org/patterns/wp-json/wp/v2';

const useAxios = ({
  url,
  method = 'get',
  body = null,
  headers = null,
  params = {},
  deps = [],
}) => {
  const [error, setError] = useState('');
  const [loading, setloading] = useState(true);
  const [response, setResponse] = useState([]);
  const [requestHeader, setRequestHeader] = useState({});

  const [lastHeaders, setLastHeaders] = useState({});
  const [isLoadingMore, setLoadingMore] = useState(false);
  const [loadingMoreError, setLoadingMoreError] = useState('');

  const loadMore = (additionalParams = {}) => {
    setLoadingMore(true);
    setLoadingMoreError('');

    axios[method](
      url,
      {
        params: Object.assign(params, additionalParams),
      },
      JSON.parse(body)
    )
      .then(res => {
        setResponse([...response, ...res.data]);
        setLastHeaders(res.headers);
      })
      .catch(err => {
        setLoadingMore(false);
        console.log('i am unable to load more patterns');

        setLoadingMoreError(true);
      })
      .finally(() => {
        setLoadingMore(false);
      });
  };

  const fetchData = () => {
    setResponse([]);
    setError('');
    setloading(true);

    axios[method](
      url,
      {
        params,
      },
      JSON.parse(body)
    )
      .then(res => {
        setResponse(res.data);
        setRequestHeader(res.headers);
      })
      .catch(err => {
        setError(err);
      })
      .finally(() => {
        setloading(false);
      });
  };

  const refetch = () => {
    setResponse(null);
    setError('');
    setloading(true);
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [method, url, body, headers, ...deps]);

  return {
    response,
    error,
    loading,
    refetch,
    loadMore,
    headers: requestHeader,
    isLoadingMore,
    lastHeaders,
    loadingMoreError,
  };
};

export default useAxios;
