import usePatterns from '../store/patterns';

/**
 * This subscription updates the selected patterns in the session storage
 * when patterns are modified/changed in the store.
 */
export default function updateLocalPatternsSubscription() {
  usePatterns.subscribe(
    state => state.patterns,
    newPatterns => {
      const patterns = JSON.stringify(newPatterns);
      sessionStorage.setItem('gutenberghub-patterns', patterns);
    }
  );
}
