/**
 * Generates a new url from url query.
 *
 *
 * @param {string} query - Search query in key value pairs.
 * @return {string} Generated url.
 */
function createUrlFromQuery(query) {
  return (
    window.location.protocol +
    '//' +
    window.location.host +
    window.location.pathname +
    '?' +
    query
  );
}

export default createUrlFromQuery;
