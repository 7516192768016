/**
 * Generates Edit URL.
 *
 * @return {string} - Generated edit url.
 */
function generateEditUrl(patterns) {
  return '/?patterns=' + patterns.map(pattern => pattern?.id).join(',');
}

export default generateEditUrl;
