import React from 'react';

import { IconButton, HStack, Tooltip } from '@chakra-ui/react';

import Desktop from '../../icons/desktop';
import Tablet from '../../icons/tablet';
import Mobile from '../../icons/mobile';

import useUIStore from '../../store/ui';

function ResponsiveSwitcher() {
  const activeBreakpoint = useUIStore(state => state.activeBreakpoint);
  const setBreakpoint = useUIStore(state => state.setActiveBreakpoint);

  return (
    <HStack>
      <Tooltip label="Switch to desktop">
        <IconButton
          size="sm"
          icon={<Desktop />}
          onClick={() => setBreakpoint('desktop')}
          isActive={activeBreakpoint === 'desktop'}
        />
      </Tooltip>
      <Tooltip label="Switch to tablet">
        <IconButton
          size="sm"
          icon={<Tablet />}
          onClick={() => setBreakpoint('tablet')}
          isActive={activeBreakpoint === 'tablet'}
        />
      </Tooltip>
      <Tooltip label="Switch to mobile">
        <IconButton
          size="sm"
          icon={<Mobile />}
          onClick={() => setBreakpoint('mobile')}
          isActive={activeBreakpoint === 'mobile'}
        />
      </Tooltip>
    </HStack>
  );
}

export default ResponsiveSwitcher;
