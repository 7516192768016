import React, { useEffect, useState } from 'react';

import { Spinner, Box, useToast, VStack, Button } from '@chakra-ui/react';

import Pattern from './pattern';

import PatternApi from '../../api/patterns';

import useAxios from '../../hooks/use-axios';
import usePromise from '../../hooks/use-promise';

import usePatterns from '../../store/patterns';

import { isEmpty } from 'lodash';

function PatternsList() {
  const toast = useToast();

  const [page, setPage] = useState(1);

  const activeCategory = usePatterns(state => state.activeCategory);

  const {
    error,
    response: patterns,
    loading,
    headers,
    loadMore,
    isLoadingMore,
    loadingMoreError,
  } = useAxios({
    url: '/wporg-pattern',
    params: {
      page: 1,
      per_page: 10,
      _locale: 'user',
      locale: 'en_US',
      'pattern-categories': activeCategory?.id,
    },
    deps: [activeCategory],
  });

  const categoryCount = headers['x-wp-total'];
  const totalPages = Number(headers['x-wp-totalpages']);

  const setActiveCategoryCount = usePatterns(
    state => state.setActiveCategoryCount
  );

  // for updating active category count
  useEffect(() => setActiveCategoryCount(categoryCount), [categoryCount]);

  // for loading more patterns
  useEffect(() => {
    if (!isLoadingMore && !loading) {
      loadMore({ page });
    }
  }, [page]);

  useEffect(() => {
    let id = 'unable-to-obtain-patterns';

    if (error && !toast.isActive(id)) {
      toast({
        id,
        title: 'Unable to obtain Patterns from the API.',
        description:
          'There seems to be an issue obtaining data, Please try again later',
        status: 'error',
      });
    }
  }, [toast, error]);

  useEffect(() => {
    if (loadingMoreError) {
      console.log('I HAVE AN ERROR');
    }
  }, [loadingMoreError]);

  return (
    <Box p="30px">
      {loading && (
        <Box textAlign="center">
          <Spinner />
        </Box>
      )}
      <VStack spacing="20px">
        {!isEmpty(patterns) &&
          patterns.map((pattern, index) => (
            <Pattern key={index} pattern={pattern} />
          ))}
        {!isEmpty(patterns) && page < totalPages && (
          <Button
            colorScheme="blue"
            isLoading={isLoadingMore}
            onClick={() => setPage(page + 1)}
          >
            Load More
          </Button>
        )}
      </VStack>
    </Box>
  );
}

export default PatternsList;
