const Desktop = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M15 20H9"
      stroke="url(#paint0_linear_17102_17357)"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19 5H5C4.44772 5 4 5.44772 4 6V16C4 16.5523 4.44772 17 5 17H19C19.5523 17 20 16.5523 20 16V6C20 5.44772 19.5523 5 19 5Z"
      stroke="url(#paint1_linear_17102_17357)"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_17102_17357"
        x1={9}
        y1={21}
        x2="11.3077"
        y2="17.5385"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#14181F" />
        <stop offset={1} stopColor="#272A31" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_17102_17357"
        x1={4}
        y1={17}
        x2={22}
        y2={11}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#14181F" />
        <stop offset={1} stopColor="#272A31" />
      </linearGradient>
    </defs>
  </svg>
);

export default Desktop;
