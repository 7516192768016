import React from 'react';

import { Box } from '@chakra-ui/react';

import PatternPreview from '../pattern-preview';

import usePatterns from '../../store/patterns';

function Pattern(props) {
  const addPattern = usePatterns(state => state.addPattern);
  const setActivePattern = usePatterns(state => state.setActivePattern);
  const totalPatterns = usePatterns(state => state.patterns.length);

  return (
    <Box w="100%" borderColor="gray.200">
      <PatternPreview
        pattern={props.pattern}
        onClick={() => {
          addPattern(props.pattern);
          setActivePattern(totalPatterns);
        }}
      />
    </Box>
  );
}

export default Pattern;
