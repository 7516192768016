const Mobile = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M16 3H8C7.44772 3 7 3.44772 7 4V20C7 20.5523 7.44772 21 8 21H16C16.5523 21 17 20.5523 17 20V4C17 3.44772 16.5523 3 16 3Z"
      stroke="url(#paint0_linear_17102_17362)"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 18H12.002V18.002H12V18Z"
      stroke="url(#paint1_linear_17102_17362)"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_17102_17362"
        x1={7}
        y1={21}
        x2="19.2634"
        y2="19.2967"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#14181F" />
        <stop offset={1} stopColor="#272A31" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_17102_17362"
        x1={12}
        y1="18.002"
        x2="12.0024"
        y2="18.0014"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#14181F" />
        <stop offset={1} stopColor="#272A31" />
      </linearGradient>
    </defs>
  </svg>
);

export default Mobile;
