import React from 'react';

import { Skeleton, Stack } from '@chakra-ui/react';

function MenuSkeleton(props) {
  return (
    <Stack width="100%">
      {new Array(props?.count ?? 3).fill(0).map((_, iterator) => (
        <Skeleton key={iterator} width="100%" height="30px" />
      ))}
    </Stack>
  );
}

export default MenuSkeleton;
