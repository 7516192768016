import React from 'react';

import { HStack, Button, IconButton, Tooltip } from '@chakra-ui/react';
import { HamburgerIcon, LinkIcon, RepeatIcon } from '@chakra-ui/icons';

import CopyCodeButton from '../copy-code-button';

import ListView from '../../icons/list-view';

import useUIStore from '../../store/ui';
import usePatternsStore from '../../store/patterns';

function AppHeader() {
  const patterns = usePatternsStore(state => state.patterns);

  const resetAllPatterns = usePatternsStore(state => state.resetAllPatterns);

  const toggleSidebarVisibility = useUIStore(
    state => state.toggleSidebarVisibility
  );

  const setShareModalVisibility = useUIStore(
    state => state.setShareModalVisibility
  );

  const setNavigatorStatus = useUIStore(state => state.setNavigatorStatus);

  return (
    <HStack
      p="20px"
      bgColor="white"
      borderBottomWidth="thin"
      borderBottomColor="gray.200"
      justifyContent="space-between"
    >
      <HStack>
        <IconButton
          onClick={toggleSidebarVisibility}
          icon={<HamburgerIcon />}
        />
        <Tooltip label="Reset">
          <IconButton onClick={resetAllPatterns} icon={<RepeatIcon />} />
        </Tooltip>
      </HStack>
      <HStack spacing="10px">
        {patterns.length > 0 && (
          <Button
            variant="outlined"
            colorScheme="blue"
            leftIcon={<LinkIcon />}
            onClick={() => setShareModalVisibility(true)}
          >
            Share
          </Button>
        )}
        <CopyCodeButton />
        <Tooltip label="Navigator">
          <IconButton
            icon={<ListView />}
            onClick={() => setNavigatorStatus(true)}
          />
        </Tooltip>
      </HStack>
    </HStack>
  );
}

export default AppHeader;
