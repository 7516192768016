import React, { useEffect } from 'react';

import { VStack, Button, Text, useToast } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';

import useAxios from '../../hooks/use-axios';
import MenuSkeleton from './menu-skeleton';

import usePatterns from '../../store/patterns';

function CoreCategory(props) {
  return (
    <Button
      size="sm"
      width="100%"
      variant="ghost"
      isActive={props.isActive}
      justifyContent="space-between"
      rightIcon={<ChevronRightIcon />}
      onClick={() =>
        props.setActiveCategory(props.isActive ? null : props.category)
      }
    >
      {props.category.name}
    </Button>
  );
}

function CoreCategories() {
  const toast = useToast();

  const { response, loading, error } = useAxios({
    url: '/pattern-categories',
  });
  const activeCategory = usePatterns(state => state.activeCategory);
  const setActiveCategory = usePatterns(state => state.setActiveCategory);

  useEffect(() => {
    if (error) {
      const id = 'unable-to-fetch-categories';

      console.error(error);

      if (!toast.isActive(id)) {
        toast({
          id,
          title: 'Unable to obtain data.',
          description:
            'Unable to obtain data from the API. Please check your internet connection and try again.',
          status: 'error',
          isClosable: true,
        });
      }
    }
  }, [error]);

  return (
    <VStack width="100%" alignItems="flex-start" my="20px">
      <Text
        fontWeight="semibold"
        fontSize="xs"
        color="gray.600"
        textAlign="left"
      >
        CORE
      </Text>
      {loading && <MenuSkeleton count={6} />}
      {!loading &&
        !error &&
        response.map((category, index) => {
          const isActive = category?.id === activeCategory?.id;

          return (
            <CoreCategory
              key={index}
              isActive={isActive}
              category={category}
              setActiveCategory={setActiveCategory}
            />
          );
        })}
    </VStack>
  );
}

export default CoreCategories;
