import React, { useState, useLayoutEffect, useEffect } from 'react';

import {
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalContent,
  Input,
  InputGroup,
  InputRightElement,
  useClipboard,
  IconButton,
  useToast,
  HStack,
  VStack,
  Tooltip,
  Text,
  Heading,
} from '@chakra-ui/react';

import { CopyIcon, ExternalLinkIcon } from '@chakra-ui/icons';

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
  TwitterShareButton,
  RedditIcon,
  RedditShareButton,
  LinkedinIcon,
  LinkedinShareButton,
} from 'react-share';

import useUIStore from '../../store/ui';
import usePatterns from '../../store/patterns';

import generatePreviewURL from '../../functions/generate-preview-url';

function ShareModal() {
  const patterns = usePatterns(state => state.patterns);

  const generatedURL = generatePreviewURL(patterns);
  const shareModalVisibility = useUIStore(state => state.shareModalVisibility);
  const setShareModalVisibility = useUIStore(
    state => state.setShareModalVisibility
  );

  const toast = useToast();

  const { hasCopied, onCopy } = useClipboard(generatedURL);

  useEffect(() => {
    if (!hasCopied) return;

    toast({
      title: 'Successfully Copied Preview Url',
      status: 'success',
    });
  }, [hasCopied]);

  const tagline = 'Hey there! Check this awesome gutenberg page.';

  return (
    shareModalVisibility && (
      <Modal
        size="lg"
        isOpen={shareModalVisibility}
        closeOnOverlayClick={false}
        onClose={() => setShareModalVisibility(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader borderBottom="1px solid" borderBottomColor="gray.200">
            <Text>Share</Text>
          </ModalHeader>
          <ModalCloseButton size="lg" />
          <ModalBody py="30px">
            <VStack alignItems="flex-start">
              <Heading size="sm">1. Direct Link</Heading>
              <Text color="gray.500">
                You can share this direct link with anyone.
              </Text>
              <InputGroup size="md">
                <Input value={generatedURL} pr="4.5rem" type="url" readOnly />
                <InputRightElement width="5.5rem">
                  <HStack>
                    <Tooltip label="Copy">
                      <IconButton
                        size="sm"
                        h="1.75rem"
                        onClick={onCopy}
                        icon={<CopyIcon />}
                      />
                    </Tooltip>
                    <Tooltip label="Visit">
                      <IconButton
                        as="a"
                        size="sm"
                        h="1.75rem"
                        target="_blank"
                        href={generatedURL}
                        icon={<ExternalLinkIcon />}
                      />
                    </Tooltip>
                  </HStack>
                </InputRightElement>
              </InputGroup>
            </VStack>
            <VStack alignItems="flex-start" py="20px">
              <Heading size="sm">2. Social Share</Heading>
              <Text color="gray.500">
                You can share socially with the following links.
              </Text>
              <HStack>
                <FacebookShareButton url={generatedURL} quote={tagline}>
                  <FacebookIcon size={36} />
                </FacebookShareButton>
                <TwitterShareButton url={generatedURL} title={tagline}>
                  <TwitterIcon size={36} />
                </TwitterShareButton>
                <RedditShareButton url={generatedURL} title={tagline}>
                  <RedditIcon size={36} />
                </RedditShareButton>
                <LinkedinShareButton url={generatedURL} title={tagline}>
                  <LinkedinIcon size={36} />
                </LinkedinShareButton>
              </HStack>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  );
}

export default ShareModal;
