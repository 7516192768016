import React from 'react';

import { RepeatIcon } from '@chakra-ui/icons';
import { Text, Heading, Center, VStack, Button } from '@chakra-ui/react';

import loaders from '../../loaders';

function FailedToLoadPatterns() {
  return (
    <Center h="100vh">
      <VStack>
        <Heading>Oops, Seems like there's an issue</Heading>
        <Text color="gray.400" fontSize="2xl">
          Sorry for the incovenience, There seems to be an issue with the API.
          Please try again later.
        </Text>
        <Button
          colorScheme="blue"
          rightIcon={<RepeatIcon />}
          onClick={loaders['load-selected-patterns']}
        >
          Try Again
        </Button>
      </VStack>
    </Center>
  );
}

export default FailedToLoadPatterns;
