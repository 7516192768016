import { useRef, useEffect } from 'react';

import loaders from '../../loaders';

function LoadersProvider() {
  const initializedLoaders = useRef([]);

  useEffect(() => {
    for (const loaderId in loaders) {
      const initializeLoader = loaders[loaderId];

      if (!initializedLoaders.current.includes(loaderId)) {
        initializeLoader();
        initializedLoaders.current.push(loaderId);
      }
    }
  }, []);

  return null;
}

export default LoadersProvider;
