import React from 'react';

import { Box, Spinner, HStack, Text, Image } from '@chakra-ui/react';

function FullScreenLoader() {
  const randomLoadingTexts = [
    'Creating your awesome page...',
    'Obtaining awesome patterns...',
    'Just give me some time...',
    'Just a moment...',
  ];

  const getRandomPrompt = () => {
    return randomLoadingTexts[
      Math.floor(Math.random() * randomLoadingTexts.length)
    ];
  };

  return (
    <Box
      w="100%"
      h="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Image src="/gutenberghub-full.png" width="50px" />
      <HStack spacing="20px">
        <Spinner size="lg" />
        <Text fontSize="2xl" fontWeight="bold">
          {getRandomPrompt()}
        </Text>
      </HStack>
    </Box>
  );
}

export default FullScreenLoader;
