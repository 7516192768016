import React from 'react';

import FailureProvider from '../providers/failure-provider';
import FullScreenLoaderProvider from '../providers/fullscreen-loader-provider';

import PreviewHeader from '../components/preview-header';
import ContentPatterns from '../components/content-patterns';

import usePatternsStore from '../store/patterns';

import NoDirectVisit from '../components/no-direct-visit';

function Preview() {
  const isLoading = usePatternsStore(state => state.loadingPatterns);
  const hasPatterns = usePatternsStore(state => state.patterns.length > 0);

  return (
    <FailureProvider>
      <FullScreenLoaderProvider loading={isLoading}>
        {hasPatterns ? (
          <>
            <PreviewHeader />
            <ContentPatterns
              fullHeight
              additionalStyles={{ height: 'calc(100vh - 101px)' }}
            />
          </>
        ) : (
          <NoDirectVisit />
        )}
      </FullScreenLoaderProvider>
    </FailureProvider>
  );
}

export default Preview;
