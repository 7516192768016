/**
 * This utility method helps to add a new classname to an html element (in html string format).
 *
 * @param {string} elementAsString - HTML Element content.
 * @param {string} className - Class to add.
 *
 * @return {string} Element with class added.
 */
function addClass(elementAsString, className) {
  // Using a dummy/fake container, to easily manipulate the element from.
  const fakeContainer = document.createElement('div');

  fakeContainer.classList.add('dummy-container');

  fakeContainer.innerHTML = elementAsString;

  const element = fakeContainer.querySelector('div');

  element.classList.add(className);

  return element.outerHTML;
}

export default addClass;
