/**
 * All Subscriptions should be included here
 */
import updateQueryPatternsSubscription from './update-query-patterns';
import updateLocalPatternsSubscription from './update-local-patterns';

const subscriptions = {
  'update-query-patterns': updateQueryPatternsSubscription,
  'update-local-patterns': updateLocalPatternsSubscription,
};

export default subscriptions;
