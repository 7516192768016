import React from 'react';

import { Grid, Box } from '@chakra-ui/react';

import Sidebar from '../components/sidebar';
import AppHeader from '../components/app-header';
import Navigator from '../components/navigator';
import ShareModal from '../components/share-modal';
import BrowserSandbox from '../components/browser-sandbox';
import ContentPatterns from '../components/content-patterns';
import EmptyPlaceholder from '../components/empty-placeholder';

import FailureProvider from '../providers/failure-provider';
import FullScreenLoaderProvider from '../providers/fullscreen-loader-provider';
import LowBreakPointNotificationProvider from '../components/low-breakpoint-notification-provider';

import useUIStore from '../store/ui';
import usePatterns from '../store/patterns';

function AppPage() {
  const isSidebarOpen = useUIStore(state => state.isSidebarOpen);
  const hasPatterns = usePatterns(state => state.patterns.length > 0);
  const isLoadingPattern = usePatterns(state => state.loadingPatterns);

  const setActiveCategory = usePatterns(state => state.setActiveCategory);

  return (
    <FailureProvider>
      <FullScreenLoaderProvider loading={isLoadingPattern}>
        <LowBreakPointNotificationProvider>
          <Grid gridTemplateColumns={isSidebarOpen ? '2fr 10fr' : '12fr'}>
            {isSidebarOpen && <Sidebar />}
            <Box bgColor="gray.50" onClick={() => setActiveCategory(null)}>
              <AppHeader />
              <Box width="90%" margin="30px auto">
                <BrowserSandbox>
                  {hasPatterns ? <ContentPatterns /> : <EmptyPlaceholder />}
                </BrowserSandbox>
              </Box>
            </Box>
          </Grid>
          <Navigator />
          <ShareModal />
        </LowBreakPointNotificationProvider>
      </FullScreenLoaderProvider>
    </FailureProvider>
  );
}

export default AppPage;
