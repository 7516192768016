import { useEffect, useRef } from 'react';

import subscriptions from '../../subscriptions';

function SubscriptionsProvider() {
  const initializedSubscriptions = useRef([]);

  useEffect(() => {
    // initializing each subscription.
    for (const subscriptionId in subscriptions) {
      // excluding already initialized subscriptions.
      if (initializedSubscriptions.current.includes(subscriptionId)) {
        continue;
      }

      const initializeSubscription = subscriptions[subscriptionId];
      initializeSubscription();
      initializedSubscriptions.current.push(subscriptionId);
    }
  }, []);

  return null;
}

export default SubscriptionsProvider;
