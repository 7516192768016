import React from 'react';

import {
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerBody,
  DrawerHeader,
  Box,
  Tooltip,
  IconButton,
  HStack,
} from '@chakra-ui/react';

import {
  CopyIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  DeleteIcon,
} from '@chakra-ui/icons';

import usePatterns from '../../store/patterns';

import useUIStore from '../../store/ui';

import Pattern from '../pattern-preview';

function Navigator() {
  const isOpen = useUIStore(state => state.isNavigatorEnabled);
  const setStatus = useUIStore(state => state.setNavigatorStatus);
  const deletePattern = usePatterns(state => state.deletePattern);
  const moveUp = usePatterns(state => state.moveUp);
  const moveDown = usePatterns(state => state.moveDown);
  const duplicatePattern = usePatterns(state => state.duplicatePattern);

  const setActivePattern = usePatterns(state => state.setActivePattern);

  const patterns = usePatterns(state => state.patterns);

  let hoverCSS = {
    '.navigator-controls': {
      bgColor: '#00000080',
      opacity: '1',
    },
  };
  return (
    isOpen && (
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={() => setStatus(false)}
      >
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">Navigator</DrawerHeader>
          <DrawerCloseButton size="lg" />
          <DrawerBody>
            {patterns &&
              patterns.map((pattern, index) => {
                return (
                  <Pattern
                    hoverCSS={hoverCSS}
                    pattern={pattern}
                    boxProps={{ my: '20px' }}
                    onClick={() => setActivePattern(index)}
                  >
                    <Box
                      w="100%"
                      h="100%"
                      top="0"
                      left="0"
                      p="20px"
                      opacity="0"
                      transition="0.2s"
                      position="absolute"
                      className="navigator-controls"
                    >
                      <HStack justifyContent="space-between">
                        <HStack>
                          <Tooltip label="Delete Pattern">
                            <IconButton
                              size="sm"
                              icon={<DeleteIcon />}
                              onClick={e => {
                                e.stopPropagation();
                                deletePattern(index);
                              }}
                            />
                          </Tooltip>
                          <Tooltip label="Duplicate Pattern">
                            <IconButton
                              size="sm"
                              icon={<CopyIcon />}
                              onClick={e => {
                                e.stopPropagation();
                                duplicatePattern(index);
                              }}
                            />
                          </Tooltip>
                        </HStack>
                        <HStack>
                          <Tooltip label="Move Down">
                            <IconButton
                              size="sm"
                              icon={<ArrowDownIcon />}
                              onClick={e => {
                                e.stopPropagation();
                                moveDown(index);
                              }}
                            />
                          </Tooltip>
                          <Tooltip label="Move Up">
                            <IconButton
                              size="sm"
                              icon={<ArrowUpIcon />}
                              onClick={e => {
                                e.stopPropagation();
                                moveUp(index);
                              }}
                            />
                          </Tooltip>
                        </HStack>
                      </HStack>
                    </Box>
                  </Pattern>
                );
              })}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    )
  );
}

export default Navigator;
