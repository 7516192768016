import axios from 'axios';

/**
 * Handles all the api related functionality for patterns from wp api.
 */
class PatternsApi {
  /**
   * Obtains a list of patterns, specifically limiting to the provided ids.
   *
   * @return {Object[]} list of patterns.
   */
  static async getPatternsFromIDs(ids) {
    try {
      const patterns = await axios.get(
        'https://wordpress.org/patterns/wp-json/wp/v2/wporg-pattern',
        {
          params: {
            include: ids.join(','),
          },
        }
      );

      return patterns.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  /**
   * Get patterns from the API.
   *
   * @param {{ page: number; per_page: number; }} config
   */
  static async getPatterns(config) {
    try {
      const patterns = await axios.get(
        'https://wordpress.org/patterns/wp-json/wp/v2/wporg-pattern',
        {
          params: Object.assign(
            {
              page: 1,
              per_page: 10,
              _locale: 'user',
              locale: 'en_US',
            },
            config
          ),
        }
      );

      return patterns.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
}

export default PatternsApi;
