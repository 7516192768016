import create from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

const store = create(
  subscribeWithSelector((set, get) => ({
    patterns: [],
    categories: [],
    setCategories: categories => set(() => ({ categories })),

    /**
     * Currently active category.
     */
    activeCategory: null,
    activeCategoryCount: 0,
    setActiveCategory: category => set(() => ({ activeCategory: category })),
    setActiveCategoryCount: activeCategoryCount =>
      set(() => ({ activeCategoryCount: activeCategoryCount })),

    /**
     * Adds a new pattern
     *
     * @param {Object} newPattern - Pattern to add
     */
    addPattern: newPattern =>
      set(state => ({ patterns: [...state.patterns, newPattern] })),

    error: false,

    setError: newErrorStatus => set(() => ({ error: newErrorStatus })),

    /**
     * Loading status for selected patterns.
     */
    loadingPatterns: false,

    /**
     * Updates loading status for the selected patterns.
     * @param {boolean} newStatus
     */
    setLoadingPatternsStatus: newStatus =>
      set(() => ({ loadingPatterns: newStatus })),

    /**
     * Active pattern index.
     */
    activePattern: 0,

    /**
     * Sets the active pattern.
     */
    setActivePattern: newIndex => set(() => ({ activePattern: newIndex })),

    /**
     * Delete a pattern
     *
     * @param {Object} newPattern - Pattern to delete
     */
    deletePattern: patternIndex => {
      let patterns = [...get().patterns];
      let updatedPatterns = patterns.filter(
        (_, index) => index !== patternIndex
      );
      set({ patterns: updatedPatterns });
    },
    /**
     * moveUp a pattern
     *
     * @param {Object} newPattern - Pattern to moveUp
     */
    moveUp: patternIndex => {
      let patterns = [...get().patterns];
      let currentIndex = patterns.findIndex(
        (_, index) => index === patternIndex
      );
      let prevIndex = currentIndex - 1;
      let currentPattern = patterns[currentIndex];
      let prevPattern = patterns[prevIndex];
      const newPatterns = [...patterns];
      if (typeof prevPattern !== 'undefined') {
        newPatterns[currentIndex] = prevPattern;
        newPatterns[prevIndex] = currentPattern;
        set({ patterns: newPatterns });
      }
    },
    /**
     * moveDown a pattern
     *
     * @param {Object} newPattern - Pattern to moveDown
     */
    moveDown: patternIndex => {
      let patterns = [...get().patterns];
      let currentIndex = patterns.findIndex(
        (_, index) => index === patternIndex
      );
      let nextIndex = currentIndex + 1;
      let currentPattern = patterns[currentIndex];
      let nextPattern = patterns[nextIndex];
      let updatedPatterns = [...patterns];
      if (typeof nextPattern !== 'undefined') {
        updatedPatterns[currentIndex] = nextPattern;
        updatedPatterns[nextIndex] = currentPattern;
        set({ patterns: updatedPatterns });
      }
    },
    /**
     * duplicate a pattern
     *
     * @param {Object} newPattern - Pattern to duplicate
     */
    duplicatePattern: patternIndex => {
      let patterns = [...get().patterns];
      let currentIndex = patterns.findIndex(
        (_, index) => index === patternIndex
      );
      let currentPattern = patterns[currentIndex];
      patterns.splice(currentIndex, 0, currentPattern);
      set({ patterns });
    },
    /**
     * rest all patterns
     *
     * @param {array} - rest all patterns
     */
    resetAllPatterns: () => set({ patterns: [] }),
  }))
);

export default store;
