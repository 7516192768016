import React from 'react';

import FailedToLoadPatterns from '../../components/failed-to-load';

import usePatterns from '../../store/patterns';

function FailureProvider(props) {
  const hasError = usePatterns(state => state.error);

  return hasError ? <FailedToLoadPatterns /> : props.children;
}

export default FailureProvider;
