/**
 * Generates Preview URL.
 *
 * @return {string} - Generated preview url.
 */
function generatePreviewURL(patterns) {
  let url = window.location.origin.concat('/preview/?');

  url = url + 'patterns=' + patterns.map(pattern => pattern?.id).join(',');

  return url;
}

export default generatePreviewURL;
