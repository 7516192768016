import React from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
} from '@chakra-ui/react';

function Notification() {
  return (
    <Modal isOpen={true}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Your Screen Size is way too low</ModalHeader>
        <ModalBody>
          In order to continue, please open this app on a greater sized device.
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default Notification;
