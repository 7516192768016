import React from 'react';

function BigOwl() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 400 600"
      width={200}
      height={400}
      style={{ marginBottom: '-45px' }}
    >
      <rect fill="rgb(255,255,255)" x={0} y={0} width={400} height={600} />
      <g transform="translate(-35.0 -13.0) rotate(0.0 248.5 305.0)  scale(0.6724803972640737 0.6736460818093472)">
        <svg width="739.055" height="905.52" viewBox="0.0 0.0 739.055 905.52">
          <path
            d="m164.646 671.27s-25.019 42.225-22.973 62.944 5.1 22.538 19.767 16.494 39.213-29.673 39.213-29.673-2.829 18.827 13 20.117c43.05 3.512 95.788-48.062 109.7-58.972a119.328 119.328 0 0 1 27.947-16.229c6.805 10.46 17.934 18.744 33.287 24.9 0 0 2.726-29.43 12.775-46.239s39.6-42.865 42.434-64.852 11.16-57.479 11.16-57.479 39.135-21.514 43.676-44.5 6.409-37.372 6.409-37.372 104.091-11.393 118.81-55.314 12.288-57.418 6.619-92.729-33.1-81.888-50.488-101.771-96.945-69.959-148.321-69.959-146.411 25.774-186.71 54.68-98.161 83.918-122.439 150-48.96 138.948-37.812 192.055 38.7 95.488 53.217 115.692 30.729 38.207 30.729 38.207z"
            fill="#fff"
            fillRule="evenodd"
          />
          <path
            d="m612.914 257.6s-33.847-114.7-174.54-135.285c-69.256-10.135-171.974 26.975-228.518 76.691-66.636 58.594-125.444 160.694-129.05 282.564-3.729 126.03 88.414 189.7 88.414 189.7"
            fill="#fff"
            fillRule="evenodd"
          />
          <path
            d="m169.212 677.27a5.969 5.969 0 0 1 -3.4-1.064c-.976-.674-24.164-16.885-47.08-49.043a236.974 236.974 0 0 1 -43.92-145.771 406.648 406.648 0 0 1 41.488-167.469c22.762-46.606 53.741-87.9 89.591-119.423 60.361-53.072 165.045-88.122 233.35-78.122 74.553 10.911 119.332 48.143 143.769 77.456 26.538 31.834 35.3 60.844 35.656 62.064a6 6 0 0 1 -11.507 3.4c-.125-.416-8.657-28.3-33.713-58.2a201.221 201.221 0 0 0 -51.82-43.988c-24.638-14.589-52.942-24.3-84.124-28.864-31.967-4.678-73.239.877-116.208 15.64-41.38 14.217-80.555 35.947-107.479 59.621-34.666 30.493-64.657 70.493-86.729 115.682a394.721 394.721 0 0 0 -40.286 162.558 225.08 225.08 0 0 0 41.42 138.053c21.705 30.593 44.192 46.378 44.416 46.534a6 6 0 0 1 -3.424 10.933z"
            fill="#cccccc"
          />
          <path
            d="m465.163 204.223s-49.756-7.8-56.657 9.546c-5.87 14.754 27.159 33.536 27.159 33.536s-73.656-10.752-78.237 10.291 35.356 26.217 35.356 26.217-65.531 6.76-61.382 31.884 40.653 22.217 40.653 22.217-53.233 13.714-40.653 37.468c8.581 16.2 27.7 7.247 27.7 7.247"
            fill="#fff"
            fillRule="evenodd"
          />
          <path
            d="m347.677 391.132c-7.817 0-16.323-3.019-21.578-12.942a20.073 20.073 0 0 1 -.883-18.041c3.7-8.38 13-14.836 22.256-19.437-10.448-3.694-19.825-10.929-21.99-24.038a19.661 19.661 0 0 1 4.045-15.43c7.329-9.6 23.42-15.488 37.517-18.963-4.833-2.481-9.305-5.723-12.268-9.928a19.837 19.837 0 0 1 -3.21-16.033c2.6-11.948 17.678-17.761 46.079-17.761h.638c5.551.017 11.028.249 16.026.574-10.866-9.973-14.694-19.247-11.378-27.583 3.844-9.659 16.088-14.725 36.394-15.058a175.111 175.111 0 0 1 26.768 1.8 6 6 0 0 1 -1.857 11.856 166.01 166.01 0 0 0 -24.853-1.658c-14.074.248-23.533 3.049-25.3 7.494-1.3 3.278 2.293 9.055 9.624 15.454a105.561 105.561 0 0 0 14.929 10.65 6 6 0 0 1 -3.835 11.152 297.644 297.644 0 0 0 -36.66-2.684h-.487c-24.016 0-33.524 4.466-34.362 8.313a7.819 7.819 0 0 0 1.29 6.561c5.454 7.756 23.2 11.67 28.981 12.431a6 6 0 0 1 -.159 11.917c-12.092 1.255-45.426 7.068-54.34 18.749a7.647 7.647 0 0 0 -1.74 6.189c3.195 19.353 33.953 17.234 34.267 17.213a6 6 0 0 1 1.963 11.792c-12.209 3.159-33.151 11.73-37.36 21.277a8.047 8.047 0 0 0 .513 7.573c2.458 4.641 6.14 6.7 11.543 6.512a25.007 25.007 0 0 0 8.3-1.89 6 6 0 1 1 5.092 10.867 34.214 34.214 0 0 1 -13.965 3.072z"
            fill="#cccccc"
          />
          <path
            d="m527.774 204.572c-24.224 2.629-34.991 14.868-37.757 37.115s-4.062 37.427-20.2 55.285c-18.44 20.406-68.347 28.192-78.518 60.208-6.688 21.054 1.341 44.843 17.57 55.536s45 27.7 91.472 27.7c55.117 0 93.645-20.044 114.986-52.386 24.421-37.01 20.3-87.4 0-127.052-25.227-49.278-65.01-58.853-87.553-56.406z"
            fill="#fff"
            fillRule="evenodd"
          />
          <path
            d="m500.342 446.412c-45.414 0-74.5-15.331-94.772-28.686-18.763-12.361-27.356-39.172-19.989-62.362 6.956-21.892 29.659-33.307 49.689-43.378 12.152-6.11 23.63-11.881 30.1-19.036 14.225-15.743 15.876-29.124 18.373-49.378l.324-2.625c3.239-26.049 16.923-39.5 43.063-42.34 8.631-.941 26.106-.957 46.177 9.442 19.568 10.139 35.5 27.026 47.366 50.191a156.358 156.358 0 0 1 17.427 67.111c.573 24.686-5.568 47.5-17.762 65.98-23.78 36.034-65.273 55.081-119.996 55.081zm28.079-235.875c-20.508 2.225-29.91 11.464-32.45 31.89l-.323 2.612c-2.576 20.9-4.61 37.4-21.378 55.956-7.973 8.822-20.425 15.084-33.609 21.712-18.789 9.447-38.218 19.216-43.642 36.29-5.7 17.957.951 39.352 15.152 48.709 18.871 12.434 45.946 26.706 88.171 26.706 50.5 0 88.528-17.183 109.979-49.691 10.84-16.428 16.3-36.861 15.781-59.092a144.316 144.316 0 0 0 -16.114-61.921c-10.7-20.9-24.9-36.038-42.205-45-17.917-9.284-33.5-8.8-39.362-8.168z"
            fill="#cccccc"
          />
          <ellipse
            cx="504.302"
            cy="364.206"
            rx="18.87"
            ry="18.423"
            fill="#cccccc"
          />
          <ellipse
            cx="557.108"
            cy="302.236"
            rx="18.87"
            ry="18.423"
            fill="#cccccc"
          />
          <path
            d="m492.276 299.21a7.433 7.433 0 0 0 -7.937 10.605c5.188 10.475 15.224 23.807 32.063 21.267a7.228 7.228 0 0 0 6.034-8.9c-3.605-15.392-18.916-20.963-30.16-22.972z"
            fillRule="evenodd"
            fill="#cccccc"
          />
          <path
            d="m233.2 252.1s67.255 48.462 8.912 170.878c-34.6 72.6-72 108.4-81.377 143.507-17.515 65.583 8.485 104.785 8.485 104.785"
            fill="#fff"
            fillRule="evenodd"
          />
          <path
            d="m169.214 677.265a5.978 5.978 0 0 1 -4.994-2.679c-1.123-1.692-27.287-42.23-9.281-109.653 4.974-18.623 17.05-36.852 32.339-59.933 14.445-21.806 32.422-48.945 49.419-84.607 22.979-48.217 29.009-91.793 17.437-126.019-8.663-25.624-24.3-37.309-24.46-37.424a6 6 0 0 1 7.035-9.722c.749.54 18.467 13.557 28.5 42.433 9.054 26.077 13.338 70.821-17.676 135.894-17.36 36.424-35.595 63.952-50.247 86.072-14.686 22.166-26.286 39.673-30.753 56.402-16.53 61.893 7.454 99.567 7.7 99.94a6 6 0 0 1 -5.017 9.3z"
            fill="#cccccc"
          />
          <path
            d="m153.637 756.708c-8.047 0-12.264-4.014-14.385-7.381-10.588-16.816 8.41-58.431 12.325-66.6a6 6 0 0 1 10.822 5.185c-8.544 17.848-18.223 46.72-12.991 55.024.363.576 1.118 1.775 4.229 1.775 17.1 0 38.526-25.71 45.337-35.616a6 6 0 0 1 10.815 4.642 43.692 43.692 0 0 0 -.609 11.263c.827 9 4.795 10.122 6.1 10.491 11.677 3.3 33.785-5.624 62.234-25.122a463.765 463.765 0 0 0 41.827-32.66 6 6 0 0 1 8.014 8.933 467.747 467.747 0 0 1 -42.955 33.558c-32.4 22.221-56.754 31.249-72.379 26.839-4.513-1.274-11.956-5.2-14.27-17.241-1.747 1.953-3.659 4-5.707 6.041-13.86 13.85-26.781 20.869-38.407 20.869z"
            fill="#cccccc"
          />
          <g fillRule="evenodd">
            <path
              d="m260.2 695.056 2.609 57.131c2.89 22.474-10.2 29.559-29.787 30.384 0 0-25.136-15.125-40.11-9.617-24.7 9.088-26.069 23.552-23.939 32.654a4.027 4.027 0 0 0 7.317 1.216c4.511-7.037 12.659-16.595 23.207-15.555 14.7 1.449 18.439 9.056 18.439 9.056s-25.079 6.163-17.845 29.425a4.463 4.463 0 0 0 7.777 1.458c7.326-9.108 23.222-16.468 41.132-17.233 31.543-1.348 24.073-13.65 45.09-13.65 10.955 0 15.684 5.293 17.679 10.559a3.516 3.516 0 0 0 6.552.046c3.267-8.237 5.386-19.909-2.429-29.091-11.961-14.051-21.8-8.885-21.8-8.885l-13.7-77.9"
              fill="#cccccc"
            />
            <path
              d="m369.909 685.127s8.154 87.716-1.947 94.64c-18.815 12.9-18.168 27.729-14.851 37.276a4.876 4.876 0 0 0 8.833.707 30.791 30.791 0 0 1 21-15c27.686-5.949 34.026 15.187 57.9 12.87 21.936-2.128 19.31-2.54 36.46-4.261 6.678-.67 11.55 2.743 14.976 7.012a5 5 0 0 0 8.8-3.852c-.993-7.251-4.678-14.08-13.15-19.225a49.85 49.85 0 0 0 -27.315-7.226c-30.007 2.8-41.509-3.314-58.9-17.719l-12.79-85.223"
              fill="#cccccc"
            />
            <path
              d="m465.163 440.412c-7.3 19.39-17.712 32.1-30.446 44.58 0 0 31.15 47.633 2.323 104.828-13.609 27-31.175 35.275-43.821 62.018-11.563 24.452-8.429 39.327-8.429 39.327s-43.494-11.144-44.074-62.494a96.767 96.767 0 0 0 -32.1 5.856c-5.6 33.035-22.737 45.1-37.447 64.031 0 0-29.538-22.866-44.969-63.663s-12.292-68.406-12.292-68.406"
              fill="#fff"
            />
          </g>
          <path
            d="m271.168 704.558a5.97 5.97 0 0 1 -3.668-1.258c-1.26-.975-31.059-24.385-46.908-66.284-15.764-41.674-12.777-70.016-12.642-71.205a6 6 0 0 1 11.923 1.355c-.027.251-2.709 26.872 11.943 65.606 11.1 29.355 30.042 49.238 38.6 57.177 2.059-2.46 4.125-4.822 6.15-7.135 11.338-12.957 22.048-25.195 26.136-49.291a6 6 0 0 1 3.693-4.569 102.317 102.317 0 0 1 34.318-6.283 6 6 0 0 1 6 5.933c.378 33.469 20.383 48.075 31.823 53.7.475-7.492 2.546-18.841 9.256-33.029 6.792-14.363 14.963-23.718 22.866-32.765 7.548-8.642 14.679-16.805 21.022-29.388 11.239-22.3 14.351-45.65 9.248-69.4-3.844-17.894-11.169-29.34-11.242-29.453a6.015 6.015 0 0 1 .828-7.559c12.431-12.181 22.124-24.055 29.031-42.408a6 6 0 1 1 11.231 4.227c-7.374 19.593-17.91 32.623-28.56 43.458 7.166 13.4 25.525 56.251.179 106.537-7.024 13.936-14.993 23.06-22.7 31.883s-14.976 17.144-21.056 30c-10.389 21.968-7.995 35.447-7.97 35.581a5.971 5.971 0 0 1 -7.372 6.992 67.441 67.441 0 0 1 -24.192-13.211c-10.144-8.53-22.153-23.771-24.12-48.874a95.055 95.055 0 0 0 -21.107 4.156c-5.078 25.149-16.862 38.619-28.278 51.665-3.226 3.686-6.561 7.5-9.69 11.524a5.993 5.993 0 0 1 -4.742 2.318z"
            fill="#cccccc"
          />
          <path
            d="m450.952 524.9s55.514-33.341 50.015-84.488"
            fill="#fff"
            fillRule="evenodd"
          />
          <path
            d="m450.958 530.9a6 6 0 0 1 -3.1-11.142c.522-.314 52.16-32.047 47.143-78.706a6 6 0 0 1 11.932-1.283c2.435 22.65-6.017 45.5-24.441 66.069a137.506 137.506 0 0 1 -28.451 24.2 5.968 5.968 0 0 1 -3.083.862z"
            fill="#cccccc"
          />
          <path
            d="m181.924 312.544s-33.854 55.467-28.815 71.577 37.707-10 37.707-10-36.157 63.69-22.539 90.245 45.372-40.194 45.372-40.194"
            fill="#fff"
            fillRule="evenodd"
          />
          <path
            d="m176.321 476.614c-3.931 0-9.37-1.682-13.383-9.506-6.457-12.591-4.474-32.764 5.9-59.956 2.2-5.779 4.544-11.242 6.757-16.085-6.76 3.275-13.982 5.39-19.645 3.659a12.834 12.834 0 0 1 -8.563-8.814c-1.659-5.306-2.132-15.876 12.864-46.275 7.863-15.938 16.206-29.643 16.557-30.219a6 6 0 0 1 10.243 6.252c-15.6 25.565-30.82 58.32-28.211 66.66.253.809.447.868.619.92 4.384 1.338 18.076-6.2 27.619-13.813a6 6 0 0 1 8.962 7.649 283.812 283.812 0 0 0 -16.04 34.473c-8.807 23.14-11.073 40.923-6.38 50.072.359.7 1.615 2.983 2.72 2.983h.012c.642-.01 6.637-.664 19.293-20.446a237.016 237.016 0 0 0 12.59-22.57 6 6 0 0 1 10.836 5.155 244.558 244.558 0 0 1 -13.257 23.786c-11.282 17.659-20.587 25.943-29.283 26.073z"
            fill="#cccccc"
          />
          <path
            d="m409.068 787.718c-8.932.869 32.525-29.588 57.785-30.681 20.989-.908 32.441 5.763 38.106 16.37a5.561 5.561 0 0 1 -8.233 7.062c-8.741-6.471-18.105-9.837-24.643-6.917-11.583 5.173-14.573 9.793-15.083 17.248z"
            fill="#cccccc"
            fillRule="evenodd"
          />
        </svg>
      </g>
    </svg>
  );
}

export default BigOwl;
