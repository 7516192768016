import create from 'zustand';

const uiStore = create(set => ({
  isSidebarOpen: true,

  toggleSidebarVisibility: () =>
    set(state => ({ isSidebarOpen: !state.isSidebarOpen })),

  /**
   * Active breakpoint for the browser sandbox.
   */
  activeBreakpoint: 'desktop',

  setActiveBreakpoint: newBreakpoint =>
    set(() => ({ activeBreakpoint: newBreakpoint })),

  shareModalVisibility: false,

  setShareModalVisibility: newVisibility =>
    set(() => ({ shareModalVisibility: newVisibility })),

  isNavigatorEnabled: false,

  setNavigatorStatus: newStatus =>
    set(() => ({ isNavigatorEnabled: newStatus })),
}));

export default uiStore;
