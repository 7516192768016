import React from 'react';

import { Box, Heading, HStack, VStack, Button, Image } from '@chakra-ui/react';
import SecondaryDrawer from '../secondary-drawer';

import CoreCategories from './core-categories';

function Sidebar() {
  return (
    <Box
      position="relative"
      borderRightWidth="thin"
      borderRightColor="gray.200"
      height="100vh"
      p="20px"
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
    >
      <Box>
        <HStack>
          <Image src="/gutenberghub.png" width="40px" height="40px" />
          <Heading py="20px" as="h1" size="md">
            GutenbergHub
          </Heading>
        </HStack>
        <CoreCategories />
      </Box>
      <VStack width="100%">
        <Button
          as="a"
          size="sm"
          target="_blank"
          width="100%"
          variant="outline"
          href="https://gutenberghub.com"
        >
          Visit Gutenberg Hub
        </Button>
        <Button
          as="a"
          size="sm"
          width="100%"
          target="_blank"
          variant="outline"
          href="https://themes.gutenberghub.com"
        >
          Visit Block Themes Directory
        </Button>
      </VStack>
      <SecondaryDrawer />
    </Box>
  );
}

export default Sidebar;
