import React from 'react';

import { Box, Text, Heading, VStack, Image } from '@chakra-ui/react';

function EmptyPlaceholder() {
  return (
    <Box p="50px" bgColor="white">
      <VStack height="50vh" justifyContent="center" spacing="10px">
        <Image src="/gutenberg.png" width="150" height="150" />
        <Heading>Create awesome Gutenberg Page</Heading>
        <Text>
          Quickly create awesome gutenberg wordpress pages, using the pre-made
          patterns library.
        </Text>
      </VStack>
    </Box>
  );
}

export default EmptyPlaceholder;
