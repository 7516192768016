/**
 * All loaders should be included here.
 */
import loadSelectedPatterns from './load-selected-patterns';

const loaders = {
  'load-selected-patterns': loadSelectedPatterns,
};

export default loaders;
