import usePatterns from '../store/patterns';

import { toString } from 'lodash';

import createUrlFromQuery from '../functions/create-url-from-query';

/**
 * This subscription updates the selected patterns in the URL Query
 * when patterns are modified/changed in the store.
 */
export default function updateQueryPatternsSubscription() {
  usePatterns.subscribe(
    state => state.patterns,
    newPatterns => {
      // collecting each pattern id.
      const ids = newPatterns.map(pattern => toString(pattern?.id));

      const searchParams = new URLSearchParams(window.location.search);
      const idList = ids.join(',');

      searchParams.set('patterns', idList);

      if (window.history.pushState) {
        const newUrl = createUrlFromQuery(searchParams.toString());
        window.history.pushState({ path: newUrl }, '', newUrl);
      }
    }
  );
}
