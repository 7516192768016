import React from 'react';

import { Box, Flex, HStack } from '@chakra-ui/react';
import getCalculatedBreakpointSize from '../../functions/get-calculated-breakpoint-size';

import ResponsiveSwitcher from './responsive-switcher';

import useUIStore from '../../store/ui';

function BrowserSandbox(props) {
  const activeBreakpoint = useUIStore(state => state.activeBreakpoint);
  const size = getCalculatedBreakpointSize(activeBreakpoint);

  return (
    <Box
      w={size}
      transition=".25s"
      borderWidth="thin"
      borderColor="gray.200"
      m="auto"
    >
      <Flex
        borderBottomWidth="thin"
        borderBottomColor="gray.100"
        justifyContent="space-between"
        bgColor="gray.100"
        p="20px"
      >
        <HStack>
          <Box
            bgColor="#f26c6f"
            borderRadius="100%"
            width="15px"
            height="15px"
          />
          <Box
            bgColor="#ffc554"
            borderRadius="100%"
            width="15px"
            height="15px"
          />
          <Box
            bgColor="#5dce91"
            borderRadius="100%"
            width="15px"
            height="15px"
          />
        </HStack>
        <ResponsiveSwitcher />
      </Flex>
      {props.children}
    </Box>
  );
}

export default BrowserSandbox;
