import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { noop } from 'lodash';

const IFrame = ({ children, ...props }) => {
  const [contentRef, setContentRef] = useState(null);
  const mountNode = contentRef?.contentWindow?.document?.body;
  const headNode = contentRef?.contentWindow?.document.head;

  const shouldMinimize = props?.minimize ?? false;

  useEffect(() => {
    if (contentRef && props.onRefMount) {
      props.onRefMount(contentRef);
    }
  }, [contentRef]);

  useEffect(() => {
    if (mountNode) {
      mountNode.style.zoom = props?.zoom ?? 1;
    }
  }, [mountNode]);

  return (
    <iframe
      title="pattern-preview"
      ref={setContentRef}
      onClick={props?.onClick ?? noop}
      scrolling={shouldMinimize ? 'no' : 'yes'}
      style={
        shouldMinimize
          ? {
              border: 'none',
              width: 1200,
              maxWidth: 'none',
              height: 800,
              transform: 'scale(0.253333)',
              transformOrigin: 'left top',
              pointerEvents: 'none',
              ...props.additionalStyles,
            }
          : { width: '100%', height: '70vh', ...props.additionalStyles }
      }
    >
      {mountNode && createPortal(children, mountNode)}
      {headNode && createPortal(props.head, headNode)}
    </iframe>
  );
};

IFrame.defaultProps = {
  additionalStyles: {},
};

export default IFrame;
